import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";

import Users from "./Users";
import Feedback from "./Feedback";
import Notifications from "./Notifications";
import AccessControl from "./AccessControl";
import {
  getAllUsers,
  getAllOrgs,
  getOrgActivity,
  getFeedback,
  srvGetRbacRules,
  getOrgRequests,
} from "../../requests";
import {
  ADMIN_CONSOLE_RBAC,
  ADMIN_CONSOLE_FEEDBACK,
  ADMIN_CONSOLE_NOTIFICATIONS,
  ADMIN_CONSOLE_USERS,
  ADMIN_CONSOLE_ORGS,
} from "../../settings";

import styles from "../../styles/AdminConsole.module.scss";
import Organizations from "./Organizations";

function AdminConsole(props) {
  const [component, setComponent] = useState(ADMIN_CONSOLE_USERS);
  const [users, setUsers] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [orgActivity, setOrgActivity] = useState([]);
  const [userReqs, setUserReqs] = useState([]);
  const [rbacRules, setRbacRules] = useState([]);
  const [feedback, setFeedback] = useState([]);

  // console.log(JSON.stringify(users, null, 2))

  const { sneakbar } = useOutletContext();

  function onClickChangeComponent(newComponent) {
    return (event) => {
      setComponent(newComponent);
    };
  }

  function refreshRbacRules() {
    srvGetRbacRules()
      .then((response) => {
        setRbacRules(response);
      })
      .catch((error) => {
        sneakbar.setMessage(error.message || "Failed to fetch RBAC rules");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  function refreshUsers() {
    getAllUsers()
      .then((response) => {
        setUsers(response);
      })
      .catch((error) => {
        sneakbar.setMessage("Failed to fetch the users");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  function refreshOrganizations() {
    getAllOrgs()
      .then((response) => {
        setOrganizations(response);
        refreshOrgActivity(response.map((org) => org.name));
      })
      .catch((error) => {
        sneakbar.setMessage("Failed to fetch the organizations");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  function refreshOrgActivity(org) {
    getOrgActivity(org)
      .then((response) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const startDate = new Date(currentYear, 0, 1); // January 1st of the current year
        const endDate = new Date(currentYear, 11, 31); // December 31st of the current year

        const calendarData = [];

        for (
          let date = startDate;
          date <= endDate;
          date.setDate(date.getDate() + 1)
        ) {
          const isoDateString = date.toISOString().split("T")[0];
          let found = false;
          for (let i = 0; i < response.length; i++) {
            if (response[i].date === isoDateString) {
              calendarData.push({
                date: isoDateString,
                count: response[i].count,
                level: 4,
              });
              found = true;
              break;
            }
          }
          if (!found) {
            calendarData.push({ date: isoDateString, count: 0, level: 0 });
          }
        }
        let orgActivity = {
          calendar: calendarData,
          data: response,
        };
        setOrgActivity(orgActivity);
      })
      .catch((error) => {
        sneakbar.setMessage("Failed to fetch the organization activity");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  function refreshUserReqs(orgs, date) {
    getOrgRequests(orgs, date)
      .then((response) => {
        setUserReqs(response);
      })
      .catch((error) => {
        sneakbar.setMessage("Failed to fetch the user requests");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });
  }

  useEffect(() => {
    getFeedback()
      .then((response) => {
        setFeedback(response);
      })
      .catch((error) => {
        sneakbar.setMessage("Failed to fetch the feedback");
        sneakbar.setSeverity("error");
        sneakbar.setOpen(true);
      });

    refreshUsers();
    refreshRbacRules();
    refreshOrganizations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.adminConsole}>
      <Box sx={{ width: 250, bgcolor: "background.paper" }}>
        <List>
          {/*<ListItem disablePadding onClick={onClickChangeComponent(ADMIN_CONSOLE_DASHBOARD)}>*/}
          {/*  <ListItemButton>*/}
          {/*    <ListItemText primary={ADMIN_CONSOLE_DASHBOARD} />*/}
          {/*  </ListItemButton>*/}
          {/*</ListItem>*/}
          <ListItem
            disablePadding
            onClick={onClickChangeComponent(ADMIN_CONSOLE_USERS)}
          >
            <ListItemButton>
              <ListItemText primary={ADMIN_CONSOLE_USERS} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={onClickChangeComponent(ADMIN_CONSOLE_ORGS)}
          >
            <ListItemButton>
              <ListItemText primary={ADMIN_CONSOLE_ORGS} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={onClickChangeComponent(ADMIN_CONSOLE_RBAC)}
          >
            <ListItemButton>
              <ListItemText primary={ADMIN_CONSOLE_RBAC} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={onClickChangeComponent(ADMIN_CONSOLE_FEEDBACK)}
          >
            <ListItemButton>
              <ListItemText primary={ADMIN_CONSOLE_FEEDBACK} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            onClick={onClickChangeComponent(ADMIN_CONSOLE_NOTIFICATIONS)}
          >
            <ListItemButton>
              <ListItemText primary={ADMIN_CONSOLE_NOTIFICATIONS} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "rgba(255, 255, 255, 0.1)",
          display: "flex",
          flexWrap: "wrap",
          gap: "30px",
          padding: "30px",
        }}
      >
        <Typography
          variant="h5"
          gutterBottom
          sx={{ width: "100%", margin: "0", color: "#ffffff" }}
        >
          {component}
        </Typography>

        {/*{component === ADMIN_CONSOLE_DASHBOARD && <Dashboard/>}*/}
        {component === ADMIN_CONSOLE_USERS && (
          <Users users={users} refreshUsers={refreshUsers} />
        )}
        {component === ADMIN_CONSOLE_ORGS && (
          <Organizations
            orgs={organizations}
            refreshOrgs={refreshOrganizations}
            orgActivity={orgActivity}
            refreshOrgActivity={refreshOrgActivity}
            userReqs={userReqs}
            refreshUserReqs={refreshUserReqs}
          />
        )}
        {component === ADMIN_CONSOLE_RBAC && (
          <AccessControl
            rbacRules={rbacRules}
            refreshRbacRules={refreshRbacRules}
          />
        )}
        {component === ADMIN_CONSOLE_FEEDBACK && (
          <Feedback feedback={feedback} />
        )}
        {component === ADMIN_CONSOLE_NOTIFICATIONS && <Notifications />}
      </Box>
    </div>
  );
}

export default AdminConsole;
