import axios from 'axios';

import _kc from '../keycloak';
import {
  MASTER_ROLE,
  URL_SHORTENER,
  INVITE_API,
  INVITES_PATH,
  INVITATION_PENDING,
  INVITATION_ACCEPTED,
  INVITE_USER_PATH,
  RESEND_EMAIL,
  CHECK_ACCOUNT_PATH,
  API,
  FEEDBACK_PATH,
  GETFEEDBACKS_PATH,
  FETCH_QUOTAS_PATH,

  ATHENA_URL,
  UTI_URL,
  WAYBACK_MACHINE_URL
} from '../settings';

export function getEmail() {
  return _kc.tokenParsed.email;
}

export function getNoInvitations() {
  return 3;
}

export function getQuota() {
  return 100;
}

export function getName() {
  return _kc.tokenParsed.name || 'UNKNOWN';
}

export function isMaster() {
  return _kc.tokenParsed.realm_access.roles.find(role => role === MASTER_ROLE);
}

export function isPendingAccount() {
  return _kc.tokenParsed.pending;
}

export async function getUsers() {
  const response = await axios.get(INVITE_API + INVITES_PATH);
  const { ok, invited, error_message } = response.data;
  if (!ok) throw new Error(error_message);

  let invitees = [];
  for (let i = invited.length - 1; i >= 0; i--) {
    invitees.push({
      email: invited[i].email,
      status: invited[i].pending === 0 ? INVITATION_ACCEPTED : INVITATION_PENDING,
      package: getPackage(),  // TODO
      lastLogin: '21.05.2023 11:45 AM',  // TODO
      usedRequests: 34,  // TODO
      inviter: getEmail(),
    });
  }
  return invitees;

  // return [
  //   {
  //     email: 'test@test.com',
  //     status: INVITATION_ACCEPTED,
  //     package: getPackage(),  // TODO
  //     lastLogin: '21.05.2023 11:45 AM',  // TODO
  //     usedRequests: 34,  // TODO
  //     inviter: getEmail(),
  //   }
  // ];
}

export async function getAllUsers() {
  const response = await axios.get(API + '/getUsers');
  const { Ok, Reason, Users } = response.data.data;
  if (!Ok) throw new Error(Reason);

  const roleFilter = (role) => {
    if (role.startsWith('default-roles-')) return false;
    if (role === 'offline_access') return false;
    if (role === 'uma_authorization') return false;
    return true;
  }

  return Users.map(user => ({
    id: user.Id,
    email: user.Email,
    organization: user.Organization,
    roles: user.Roles.filter(roleFilter),
    inviter: user.Parent || '-',
    status: INVITATION_ACCEPTED,  // TODO
    lastSeen: user.LastSeen == 0 ? "-" : new Date(user.LastSeen * 1000).toLocaleString(),
    totalLogins: user.TotalLogins,
  }));
}

export async function getAllOrgs() {
  const response = await axios.get(API + '/getOrgs');
  const { Ok, Reason, Orgs } = response.data.data;
  if (!Ok) throw new Error(Reason);

  return Orgs.map(org => ({
    name: org.Name,
    firstInvite: org.FirstInvite,
    createdAt: new Date(org.CreatedAt).toLocaleString(),
    lastSeen: org.LastSeen == 0 ? "-" : new Date(org.LastSeen * 1000).toLocaleString(),
    totalLogins: org.TotalLogins,
  }));
}

export async function getOrgActivity(org) {
  let totaldata = [];
  for (let i = 0; i < org.length; i++) {
    const response = await axios.post(API + '/getOrgActivity', {
      'Org': org[i],
    });
    const { Ok, Reason, Data } = response.data.data;
    if (!Ok) throw new Error(Reason);
    for (let j = 0; j < Data.length; j++) {
      // check if an object with field Date already exists
      let found = false;
      for (let k = 0; k < totaldata.length; k++) {
        if (totaldata[k].Date === Data[j].Date) {
          totaldata[k].Count += Data[j].Count;

          // merge projects
          for (let l = 0; l < Data[j].Projects.length; l++) {
            let found2 = false;
            for (let m = 0; m < totaldata[k].Projects.length; m++) {
              if (totaldata[k].Projects[m].Project === Data[j].Projects[l].Project) {
                totaldata[k].Projects[m].Count += Data[j].Projects[l].Count;
                found2 = true;
                break;
              }
            }
            if (!found2) {
              totaldata[k].Projects.push(Data[j].Projects[l]);
            }
          }
          found = true;
          break;
        }
      }
      if (!found) {
        totaldata.push(Data[j]);
      }
    }
  }
  return totaldata.map(activity => ({
    date: activity.Date,
    count: activity.Count,
    projects: activity.Projects,
  }));
}

export async function getOrgRequests(org, date) {
  const start = Math.floor(new Date(date).getTime() / 1000);
  const end = start + 24 * 60 * 60;

  let totaldata = [];
  for (let i = 0; i < org.length; i++) {
    const response = await axios.post(API + '/getOrgRequests', {
      'Org': org[i],
      'Start': start,
      'End': end,
    });
    const { Ok, Reason, Data } = response.data.data;
    if (!Ok) throw new Error(Reason);
    totaldata = totaldata.concat(Data);
  }
  return totaldata;
}

export async function srvGetRbacRules() {
  const response = await axios.get(INVITE_API + '/admin/rbac_get');
  if (!response.data?.ok) throw new Error("Can't get RBAC rules");

  return response.data.rules;
}

export async function srvDeleteRbacRule(rule) {
  const response = await axios.post(INVITE_API + '/admin/rbac_del', rule);
  if (!response.data?.ok) throw new Error("Can't delete RBAC rule");
}

export async function srvAddRbacRule(resource, action, role, quota) {
  const postData = {
    resource, action, role,
    other_info: { quota }
  }
  const response = await axios.post(INVITE_API + '/admin/rbac_add', postData);
  if (!response.data?.ok) throw new Error("Can't create RBAC rule");
}

export async function getFeedback() {
  const response = await axios.get(API + GETFEEDBACKS_PATH);
  if (!(response.data?.feedbacks)) throw new Error("Can't get feedbacks");
  //return response.data.feedback;

  return response.data.feedbacks.map(feedback => ({
    user: feedback.user,
    sentAt: new Date(feedback.createdAt * 1000).toLocaleString(),
    title: feedback.title,
    description: feedback.text,
  }));
}

export async function checkAccount() {
  await axios.post(INVITE_API + CHECK_ACCOUNT_PATH);
}

export function logout() {
  _kc.logout();
}

export async function sendNotification(notification) {
  const data = {
    Users: notification.to,
    Title: notification.title,
    Text: notification.description
  }
  const response = await axios.post(API + '/addNotification', data);
  console.log(JSON.stringify(response, null, 2))
  const { ok, error_message } = response.data;
  if (!ok) throw new Error(error_message);
}

export async function getNotifications() {
  const response = await axios.get(API + '/getNotifications');
  if (!(response.data?.data?.ok)) throw new Error("Can't retrieve notifications");
  const serverNotifications = response.data.data.notifications || [];
  console.log("TODO: display notifications popup and mark them as seen:", serverNotifications);

  return serverNotifications.map(notification => ({
    id: notification.id,
    title: notification.title,
    description: notification.text,
    seen: notification.seen
  }));  // [{id, title, text, seen}]
}

export async function markNotificationSeen(notificationId) {
  const response = await axios.post(API + '/setNotificationSeen', { notificationId });
  if (!(response.data?.data?.Ok)) throw new Error("Can't mark notification as seen");
}

export async function deleteNotification(notificationId) {
  const response = await axios.post(API + '/deleteNotification', { notificationId });
  if (!(response.data?.data?.Ok)) throw new Error("Can't delete notification");
}

export function getPackage() {
  return 'Standard Athena Package';
}

export async function getProjects() {
  return [
    {
      name: 'Athena',
      description: 'A private investigator that queries, correlates and augments data as it’s being added to a graph.',
      link: ATHENA_URL,
      types: ['email', 'phone number', 'ip', 'onion', 'term']
    },
    {
      name: 'Underground Threat Intelligence',
      description: 'Unlocks intelligence hidden on darknet, deepweb, forums and chat services.',
      link: UTI_URL,
      types: ['email', 'phone number', 'ip', 'onion', 'term']
    },
    {
      name: 'Wayback Machine',
      description: 'View and inspect darknet resources historically, as they apperead in our crawling history.',
      link: WAYBACK_MACHINE_URL,
      types: ['onion']
    },
    // {
    //   name: 'Sherlock',
    //   description: 'Hypervise a custom VM to observe OS behaviour as you browse links or run any apps and binaries.',
    //   link: ''
    // },
    // {
    //   name: 'Threat Intelligence',
    //   description: 'Our Threat Intelligence platform can be queried here.',
    //   link: ''
    // },
    // {
    //   name: 'Special Projects',
    //   description: `We are highly confident you'll like what's behind this door, but you need a special account to get there.`,
    //   link: ''
    // },
    // {
    //   name: 'Other projects',
    //   description: 'As we grow, so does this list. Come back soon to see more projects.',
    //   link: ''
    // },
  ];

  /*
  const response = await axios.get(API + TYPES_PATH);
  const {ok, data, error_message} = response.data;
  if (!ok) throw new Error(error_message);
 
  let projects = [];
  for (let projectName in data) {
    projects.push({
      name: projectName,
      description: data[projectName].description,
      link: data[projectName].source || '',
      types: data[projectName].types
    });
  }
  return projects;
  */
}

export async function encodeData(data) {  // TODO send error message
  let value = data;
  if (!value.startsWith('http://') && !value.startsWith('https://')) value = 'http://' + value;

  const response = await axios.put(URL_SHORTENER, { value });
  return response.data;
}

export async function sendInvitationAsAdmin(email, org, domain) {
  let postData = {
    email,
    org,
    domain
  };

  const response = await axios.post(INVITE_API + INVITE_USER_PATH, postData);
  const { ok, error_message } = response.data;
  if (!ok) throw new Error(error_message);
}

export async function sendInvitation(email) {
  let user = {
    email,
    invites: getNoInvitations(),
    quota: {
      athena: getQuota()
    }
  };
  const response = await axios.post(INVITE_API + INVITE_USER_PATH, user);
  const { ok, data, error_message } = response.data;
  if (!ok) throw new Error(error_message);

  return {
    id: data.id,
    status: INVITATION_PENDING,
    email,
    package: getPackage(),
    noInvitations: getNoInvitations()
  };
}

export async function getInvitations() {
  const response = await axios.get(INVITE_API + INVITES_PATH);
  const { ok, invited, error_message } = response.data;
  if (!ok) throw new Error(error_message);

  let invitees = [];
  for (let i = invited.length - 1; i >= 0; i--) {
    invitees.push({
      id: invited[i].id,
      status: invited[i].pending === 0 ? INVITATION_ACCEPTED : INVITATION_PENDING,
      email: invited[i].email,
      package: getPackage(),  // TODO
      noInvitations: getNoInvitations()  // TODO
    });
  }
  return invitees;
}

export async function cancelInvitation(id) {
  const config = {
    data: { invited_id: id }
  };
  const response = await axios.delete(INVITE_API + INVITES_PATH, config);
  const { ok, error_message } = response.data;
  if (!ok) throw new Error(error_message);
}

export async function resendInvitationEmail(email) {
  const data = { email };
  const response = await axios.post(INVITE_API + RESEND_EMAIL, data);
  const { ok, error_message } = response.data;
  if (!ok) throw new Error(error_message);
}

export async function getAthenaQuotas() {
  const response = await axios.get(INVITE_API + FETCH_QUOTAS_PATH);
  const { ok, data, error_message } = response.data;
  if (!ok) throw new Error(error_message);

  return { athena: JSON.parse(data.data), quotas: data.quotas };
}

export async function getUserRequests(email) {
  // const response = await axios.get(INVITE_API + FETCH_QUOTAS_PATH);
  // const {ok, data, error_message} = response.data;
  // if (!ok) throw new Error(error_message);
  //
  // return {
  //   athena: JSON.parse(data.data),
  //   uti: JSON.parse(data.data),
  //   wbm: JSON.parse(data.data)
  // };

  return {
    athena: [],
    uti: [],
    wbm: []
  };
}

export async function sendFeedback(title, text) {
  const data = {
    title,
    text
  };
  const response = await axios.post(API + FEEDBACK_PATH, data);
  const { ok, error_message } = response.data;
  if (!ok) throw new Error(error_message);
}